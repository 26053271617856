import React, { Component } from 'react'

class DownloadBatPage extends Component {
    render() {

        const molnskyltUrl = this.props.query;

        function copyToClipboard() {
            const urlValue = molnskyltUrl;
            navigator.clipboard.writeText(urlValue);
            document.getElementById('copiedIndicator').style.display = 'block';
        }

        return (
            <div className="configurator-rounded-plate">
                <div className="configurator-header-plate">
                    <h5 className="configurator-header">Konfigurera skylt</h5>
                </div>
                <div className="configurator-content">
                    <div className="bold" style={{ fontSize: 20, marginBottom: 10 }}>Skylt skapad. Du är snart klar...</div>

                    <ol style={{ listStyleType: 'none', padding: 0, marginTop: 30 }}>
                        <li style={{ marginBottom: '30px' }}>
                            <p className="bold" >1. Kopiera länken:</p>
                            <div>
                                <a className="url" style={{ verticalAlign: 'text-bottom' }} href={molnskyltUrl} target="_blank" rel="noopener noreferrer" >{molnskyltUrl}</a>
                            </div>
                            <div>
                                <button onClick={copyToClipboard} className="btn btn-warning bold col-4" style={{ boxSizing: 'border-box', height: 'calc(1.5em + 0.75rem + 2px)', fontSize: '1rem', borderRadius: '0.25rem', verticalAlign: 'baseline' }}>Kopiera url</button>
                                <p id="copiedIndicator" style={{ color: 'green', display: 'none' }}>Kopierad!</p>
                            </div>
                        </li>
                        <li>
                            <p className="bold" >2. Klista in länken i din applikation eller webbläsare:</p>
                            <p>Observera att du manuellt behöver dölja adressfältet i din webbläsare. Detta gör du genom att trycka <kbd className="bold" style={{ border: '1px solid grey', borderRadius: '.25rem', backgroundColor: '#e9ecef', padding: 1, fontSize: '.9rem', color: 'black', fontWeight: '600 !important' }}>F11</kbd> i Windows och Linux.</p>
                            <p className="bold" >Klart!</p>
                        </li>
                    </ol>
                    <hr />

                </div>
            </div>
        );
    }
}

export default DownloadBatPage;
