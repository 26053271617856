import '../Monitor.css';

import React, { Component } from 'react';

import Colors from './Colors.js';
import JourneyMessage from './JourneyMessage.jsx';
import { getFormattedNumMinutes, getFormattedTime } from './Utilities';
import JourneyCanceledMessage from './JourneyCanceledMessage.jsx';

class BusJourneyItem extends Component {
  renderIcon(){
      var color = this.getBusColor();
      var textColor = this.getBusTextColor();

    var number = this.props.journey.line;
    if (isNaN(number)) {
      number = number.match(/\d+/g);
    }

    return (
      <div className="iconContainer">
        <div className="roundedPlate" style={{transform: 'scale(0.85)'}}>
          <div className="plateTitle" style={{color: textColor}}>{number}</div>
          <svg className="svgPlate" x="0px" y="0px" width="90px" height="50px" viewBox="0 0 138.2 83.6">
            <path fill={color} d="M128.2,83.6H10c-5.5,0-10-4.5-10-10V10C0,4.5,4.5,0,10,0h86.5c20.9,0,38.8,51.1,41.7,73.6 C138.9,79.1,133.7,83.6,128.2,83.6z"/>
          </svg>
        </div>        
      </div>
    );
  }
  getBusTextColor() {
    return this.props.journey.type === "Regionbuss" || this.props.journey.type === "SkåneExpressen" ? Colors.Black : Colors.White;
  }

  getBusColor() {
    return this.props.journey.type === "Stadsbuss" ? Colors.LocalBus : this.props.journey.type === "Regionbuss" || this.props.journey.type === "SkåneExpressen" ? Colors.RegionBus : Colors.CommercialBus;
  }

  renderInfoMessage(){
    return (
      <tr>
        <td colSpan="5">
          <JourneyMessage text={this.props.journey.message} width={this.props.width}/>
        </td>
      </tr>
    );
  }
  renderCanceledMessage(){
    return (
          <JourneyCanceledMessage textCols={4} canceledSignCols={1} text={this.props.journey.message} width={this.props.width}/>
    );
  }
  renderMessage() {
    if (this.props.journey.canceled) {
      return this.renderCanceledMessage();
    }
    else if (this.props.journey.message){
      return this.renderInfoMessage();
    }
    return null;
  }
  getBackgroundColor() {
    return this.props.index % 2 !== 0 ? Colors.White : "#e2e5ec";
  }
  render() {
    var countdownTime = getFormattedTime(this.props.journey.updatedTime || this.props.journey.time);
    
    if (!this.props.journey.canceled) {
        const ONE_HOUR = 60 * 60 * 1000;  
        var plusHour = new Date();
        plusHour.setHours(plusHour.getHours() + 1);    
      if (((this.props.journey.updatedTime || this.props.journey.time).getTime() - plusHour.getTime()) < ONE_HOUR) {
        countdownTime = getFormattedNumMinutes(new Date(), this.props.journey.updatedTime || this.props.journey.time);
      }
    }
    
    var directionMaxWidth = (this.props.width || 400) - 400;

    return (
      <tbody style={{background: this.getBackgroundColor()}}>
        <tr className="JourneyItem BusJourneyItem">
          <td className="JourneyItemIcon JourneyItemBusIcon">{this.renderIcon()}</td>
          <td className="Direction" style={{maxWidth: directionMaxWidth}}>{this.props.journey.direction}</td>
          <td className={(this.props.journey.canceled || this.props.journey.updatedStopPoint ? "changed" : "") + " Platform"}>{this.props.journey.platform}</td>
          <td className={(this.props.journey.canceled ? "changed" : "") + " Platform"}>{this.props.journey.updatedStopPoint}</td>
          <td className={(this.props.journey.canceled || this.props.journey.awaitTime ? "changed" : "") + " TimeCountDown"}>{countdownTime}</td>
        </tr>
        {this.renderMessage()}
        
      </tbody>
    );
  }
}

export default BusJourneyItem;
