import './ConfigApp.css';
import './ContentSizing.css';
import 'bootstrap/dist/css/bootstrap.css';

import React, { Component } from 'react';

import { background, logo, regionSkaneLogo } from '../Images.js';
import DownloadBatPage from './DownloadBatPage';
import SearchBox from './SearchBox.jsx';

class ConfigApp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fromPointData: [{
                fromPoint: null, selectedStopPoints: [], trafficTypes: []
            }],
            stopLimitHit: false,
            points: {},
            departure: 0,
            width: this.getWidth(),
            apiKey: '',
            invalidApiKey: false,
            createBtnText: 'Skapa',
            createBtnLoading: false,
            query: '',
            screenRotation: 'Landscape',
            layout: 'departureBoard',
            tableRows: 6,
            stopsNr: 1,
            iterations: [0]
        }
        window.onresize = () => {
            this.setState({ width: this.getWidth() });
        };
        this.getAreas();
        this.maxStops = 4;
    }

    getAreas() {
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.open('get', "api/Config/GetAreas", true);
        xhr.onload = function () {
            if (xhr.status !== 200) {
                this.setState({ error: xhr.status === 401 ? 'Unauthorized use of page' : 'Unknown error', loading: false });
                return;
            }
            var resultObject = JSON.parse(xhr.response);
            this.setState({ areas: resultObject.areas });
        }.bind(this);
        xhr.send();
    }

    fromChanged(item, index) {
        var fromPointData = this.state.fromPointData;
        fromPointData[index].fromPoint = item;
        this.setState({ fromPointData: fromPointData });
    }

    getMatchingPoints(text, callback) {
        if (text.length < 3) {
            callback([]);
            return;
        }

        if (this.state.points[text] && this.state.points[text].length > 0) {
            callback(this.state.points[text]);
            return;
        }

        var searchResults = this.state.areas.filter(area => this.normalizeText(area.name).includes(this.normalizeText(text)));

        searchResults = searchResults
            .sort((a, b) => {
                return a.name.toLowerCase() > b.name.toLowerCase() ? -1 : (a.name.toLowerCase() < b.name.toLowerCase() ? 1 : 0);
            })
            .sort((a, b) => {
                const startsWithA = a.name.toLowerCase().startsWith(text.toLowerCase());
                const startsWithB = b.name.toLowerCase().startsWith(text.toLowerCase());
                if (startsWithA)
                    return -1;
                else if (startsWithB)
                    return 1;
                else
                    return 0;
            });

        this.setState({ points: searchResults });
        callback(searchResults);
    }

    normalizeText(text) {
        return text.toLowerCase().replace(/\s/g, '')
    }

    onSelectedStopPointsChanged(value, index, event) {
        var fromPointData = this.state.fromPointData;
        var selectedStopPoints = this.state.fromPointData[index].selectedStopPoints;
        if (event.target.checked) {
            fromPointData[index].selectedStopPoints = [...selectedStopPoints, value];
            this.setState({ fromPointData: fromPointData });
        }
        else {
            fromPointData[index].selectedStopPoints = selectedStopPoints.filter(stop => stop !== value);
            this.setState({ fromPointData: fromPointData });
        }
    }

    onTrafficTypeChanged(value, index, event) {
        var fromPointData = this.state.fromPointData;
        var trafficTypes = fromPointData[index].trafficTypes;
        if (event.target.checked) {
            trafficTypes.push(value);
        } else {
            var typeIndex = trafficTypes.indexOf(value);
            if (typeIndex > -1) {
                trafficTypes.splice(typeIndex, 1);
            }
        }

        var layout = this.state.layout;
        // Do not allow trains mixed with other trafficTypes for multistops
        if (this.state.stopsNr > 1) {
            if (value === 4)
                trafficTypes = trafficTypes.filter(type => type === value);
            else
                trafficTypes = trafficTypes.filter(type => type !== 4);
        }
        // Fallback to mixed traffic compatible layout if mixed traffic is selected
        else {
            const hasMixedSelection = this.state.fromPointData.map(fromPoint => {
                const isTrainSelected = fromPoint.trafficTypes.some(trafficType => trafficType === 4);
                const isOtherSelected = fromPoint.trafficTypes.some(trafficType => trafficType !== 4);
                return isTrainSelected && isOtherSelected;
            });
            if(this.state.layout !== 'departureBoard' && hasMixedSelection.some(fromPointisMixed => fromPointisMixed))
                layout = 'departureBoard';
        }

        fromPointData[index].trafficTypes = trafficTypes;
        this.setState({ fromPointData: fromPointData, layout: layout });
    }

    validateApiKey(apiKey) {
        this.setState({ createBtnText: "Skapar...", createBtnLoading: true });
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.open('get', "api/Config/validate-api-key", true);
        xhr.setRequestHeader('x-api-key', this.state.apiKey);
        xhr.onload = function () {
            if (xhr.status === 401) {
                this.setState({ createBtnText: "Skapa", createBtnLoading: false, invalidApiKey: true });
                return;
            }
            this.createConfiguration();
        }.bind(this);
        xhr.send();
    }

    createConfiguration() {
        var DataSubscription = []
        this.state.fromPointData.forEach((o => {

            var typeFilters = o.trafficTypes.map((type) => {
                var transportType;
                var categoryType = null;

                switch (type) {
                    case 1: //city bus
                        transportType = 1;
                        categoryType = 0;
                        break;
                    case 2: //regional bus
                        transportType = 1;
                        categoryType = 1;
                        break;
                    case 3: //tram
                        transportType = 2;
                        break;
                    case 4: //train
                        transportType = 3;
                        break;
                    default: //unknown
                        console.warn(`WARNING: Unknown traffic type '${type}'`);
                }
                if (categoryType === null) {
                    return { transportTypeFilter: transportType};
                }
                else {
                    return { transportTypeFilter: transportType, categoryTypeFilter: categoryType };
                }
                
               
            })

            o.selectedStopPoints.forEach(point => {
                typeFilters.forEach(type => {
                    DataSubscription.push({ StopPointId: point.id, ...type });
                });
            });
        }))

        var body = {
            LayoutName: this.getLayoutId(this.state.layout),
            DataSubscription
        }

        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.open('POST', "api/Config/CreateWeblayoutConfig", true);
        xhr.setRequestHeader('x-api-key', this.state.apiKey);
        xhr.onload = function () {
            if (xhr.status !== 200) {
                this.setState({ error: xhr.status === 401 ? 'Unauthorized use of page' : 'Unknown error', loading: false, createBtnText: "Skapa", createBtnLoading: false });
                return;
            }
            this.setState({ query: xhr.response });
            this.setState({ showDownload: true });
        }.bind(this);
        xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhr.send(JSON.stringify(body));
    }

    renderStopPointConfig(index) {
        const isTrainSelected = this.state.fromPointData[index].trafficTypes.some(trafficType => trafficType === 4);
        
        return (
            <div key={index}>
                <label className="bold">Hållplats {index + 1}</label>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <div style={{ width: 30, backgroundColor: 'white' }} className="input-group-text bold">{this.state.departure ? 'Till' : 'Från'}</div>
                    </div>
                    <SearchBox
                        placeholder="Hållplats"
                        inputStyle={{ height: 50, borderRadius: '0px 4px 4px 0px', paddingLeft: 12 }}
                        style={{ flex: '1 1 auto', borderRadius: '0px 4px 4px 0px' }}
                        onSearch={(text, callback) => {
                            var fromPointData = this.state.fromPointData;
                            fromPointData[index].fromPoint = undefined;
                            this.setState({ fromPointData: fromPointData });
                            this.getMatchingPoints(text, callback);
                        }}
                        onSelected={(item) => {
                            this.fromChanged(item, index);
                        }}
                        resultsLimit={7} />
                </div>
                <br />
                {
                    this.state.fromPointData[index].fromPoint && (
                        <div>
                            <label className="bold" style={{ marginBottom: 15 }}>Välj läge(n)</label>
                            <br />
                            {
                                this.state.fromPointData[index].selectedStopPoints.length !== 0 ?
                                    <button id={"deselectStopsButton" + index} className="btn btn-link" onClick={() => {
                                        const updatedFromPointData = [...this.state.fromPointData];
                                        updatedFromPointData[index].selectedStopPoints = [];
                                        this.setState({ fromPointData: updatedFromPointData });
                                        document.getElementById('deselectStopsButton' + index).blur();
                                    }}>
                                        Avmarkera alla lägen
                                    </button> :
                                    <button id={"selectStopsButton" + index} className="btn btn-link" onClick={e => {
                                        const updatedFromPointData = [...this.state.fromPointData];
                                        updatedFromPointData[index].selectedStopPoints = [...this.state.fromPointData[index].fromPoint.stopPoints];
                                        this.setState({ fromPointData: updatedFromPointData });
                                        document.getElementById('selectStopsButton' + index).blur();
                                    }}>
                                        Markera alla lägen
                                    </button>
                            }


                            {this.state.fromPointData[index].fromPoint.stopPoints.map(stop => (
                                <div className="form-check" key={stop.id}>
                                    <input className="configurator-checkbox form-check-input" type="checkbox" id={stop.id} checked={this.state.fromPointData[index].selectedStopPoints.includes(stop)} onChange={(event) => { this.onSelectedStopPointsChanged(stop, index, event) }} />
                                    <label className="configurator-checkbox-label form-check-label" htmlFor={stop.id} >{stop.designation}</label>
                                </div>
                            ))}
                        </div>
                    )
                }

                <br />
                {/* Arrivals is not currently supported 
                <select className="form-control" style={{height: 50, backgroundColor: '#fff'}} value={this.state.departure} onChange={(event) => {
                    this.setState({ departure: event.target.value * 1 }
                    )}}>
                    <option value={0}>Avgångar</option>
                    <option value={1}>Ankomster</option>
                </select>
                <br /> */}
                <label className="bold" style={{ marginBottom: 15 }}>Välj trafikslag</label>

                <div className="form-check">
                    <input
                        className="configurator-checkbox form-check-input"
                        type="checkbox"
                        id={"Localbus" + index}
                        checked={this.state.fromPointData[index].trafficTypes.indexOf(1) > -1 && (!isTrainSelected || this.state.stopsNr === 1)}
                        disabled={this.state.stopsNr > 1 && isTrainSelected}
                        onChange={(event) => { this.onTrafficTypeChanged(1, index, event) }}
                    />
                    <label className="configurator-checkbox-label form-check-label" htmlFor={"Localbus" + index}>Stadsbuss</label>
                </div>
                <div className="form-check">
                    <input
                        className="configurator-checkbox form-check-input"
                        type="checkbox"
                        id={"Regionbus" + index}
                        checked={this.state.fromPointData[index].trafficTypes.indexOf(2) > -1 && (!isTrainSelected || this.state.stopsNr === 1)}
                        disabled={this.state.stopsNr > 1 && isTrainSelected}
                        onChange={(event) => { this.onTrafficTypeChanged(2, index, event) }}
                    />
                    <label className="configurator-checkbox-label form-check-label" htmlFor={"Regionbus" + index}>Regionbuss</label>
                </div>
                <div className="form-check">
                    <input
                        className="configurator-checkbox form-check-input"
                        type="checkbox"
                        id={"Tram" + index}
                        checked={this.state.fromPointData[index].trafficTypes.indexOf(3) > -1 && (!isTrainSelected || this.state.stopsNr === 1)}
                        disabled={this.state.stopsNr > 1 && isTrainSelected}
                        onChange={(event) => { this.onTrafficTypeChanged(3, index, event) }}
                    />
                    <label className="configurator-checkbox-label form-check-label" htmlFor={"Tram" + index}>Spårvagn</label>
                </div>
                <div className="form-check">
                    <input
                        className="configurator-checkbox form-check-input"
                        type="checkbox"
                        id={"Train" + index}
                        checked={this.state.fromPointData[index].trafficTypes.indexOf(4) > -1 && (!this.state.fromPointData[index].trafficTypes.some(trafficType => trafficType !== 4) || this.state.stopsNr === 1)}
                        disabled={this.state.stopsNr > 1 && this.state.fromPointData[index].trafficTypes.some(trafficType => trafficType !== 4)}
                        onChange={(event) => { this.onTrafficTypeChanged(4, index, event) }}
                    />
                    <label className="configurator-checkbox-label form-check-label" htmlFor={"Train" + index}>Tåg</label>
                </div>
                <hr />

            </div>);
    }

    renderConfigurationForm() {
        const hasMixedSelection = this.state.fromPointData.map(fromPoint => {
            const isTrainSelected = fromPoint.trafficTypes.some(trafficType => trafficType === 4);
            const isOtherSelected = fromPoint.trafficTypes.some(trafficType => trafficType !== 4);
            return isTrainSelected && isOtherSelected;
        });

        return (
            <div className="configurator-rounded-plate mx-md-3" style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                <div className="configurator-header-plate" >
                    <h5 className="configurator-header">Skapa avgångstavla</h5>
                </div>
                <div className="configurator-content">
                    <label className="bold"> Antal hållplatser </label>
                    <div className="row" style={{ marginLeft: 0, marginBottom: 15 }}>
                        <button className="btn btn-square minus col-auto" onClick={() => {
                            if (this.state.stopsNr > 1) {
                                var stopsNr = this.state.stopsNr - 1;
                                var fromPointData = this.state.fromPointData;
                                fromPointData.pop();
                                var layout = `departureBoard${(stopsNr === 1 ? "" : `_Multistop_${stopsNr}x`)}`;
                                var tableRows = this.getLayoutDefaultTableRows(layout, this.state.screenRotation, stopsNr);

                                // Empty traffic types for a fromPoint if it is mixed
                                fromPointData.map(fromPoint => {
                                    const isTrainSelected = fromPoint.trafficTypes.some(trafficType => trafficType === 4);
                                    const isOtherSelected = fromPoint.trafficTypes.some(trafficType => trafficType !== 4);
                                    if (isTrainSelected && isOtherSelected)
                                        fromPoint.trafficTypes = [];
                                    return fromPoint;
                                });

                                this.setState({
                                    layout: layout,
                                    tableRows: tableRows,
                                    stopsNr: stopsNr,
                                    fromPointData: fromPointData,
                                    iterations: [...Array(stopsNr).keys()]
                                });
                            }
                            if (this.state.stopLimitHit) {
                                document.getElementById('maxStopsIndicator').style.display = 'none';
                                this.setState({ stopLimitHit: false });
                            }
                        }}>–</button>
                        <label className="col-auto">{this.state.stopsNr}</label>
                        <button id="plusButton" className={"btn col-auto btn-square"} onClick={() => {
                            if (this.state.stopsNr < 4) {
                                var stopsNr = this.state.stopsNr + 1;
                                var fromPointData = this.state.fromPointData;
                                
                                var layout = `departureBoard${(stopsNr === 1 ? "" : `_Multistop_${stopsNr}x`)}`;
                                var tableRows = this.getLayoutDefaultTableRows(layout, this.state.screenRotation, stopsNr);

                                // Empty traffic types for a fromPoint if it is mixed
                                fromPointData.map(fromPoint => {
                                    const isTrainSelected = fromPoint.trafficTypes.some(trafficType => trafficType === 4);
                                    const isOtherSelected = fromPoint.trafficTypes.some(trafficType => trafficType !== 4);
                                    if (isTrainSelected && isOtherSelected)
                                        fromPoint.trafficTypes = [];
                                    return fromPoint;
                                });

                                this.setState({
                                    layout: layout,
                                    tableRows: tableRows,
                                    stopsNr: stopsNr,
                                    fromPointData: [...fromPointData, { stop: null, selectedStopPoints: [], trafficTypes: [] }],
                                    iterations: [...Array(stopsNr).keys()]
                                });
                            }
                            else {
                                document.getElementById('maxStopsIndicator').style.display = 'block';
                                this.setState({ stopLimitHit: true });
                            }
                            if (this.state.screenRotation === 'Landscape' && this.state.layout === 'departureBoard_OneTable') {
                                this.setState({
                                    layout: 'departureBoard',
                                    tableRows: 6
                                });
                            }
                        }}>+</button>
                        <p id="maxStopsIndicator" style={{ color: 'red', display: 'none', marginLeft: '10px', marginBottom: '0px' }}>Max 4 hållplatser kan visas samtidigt.</p>
                    </div>

                    <hr />
                    
                    {this.state.iterations.map(
                        index => {
                            return this.renderStopPointConfig(index);
                        })}
                    <label className="bold" style={{ marginBottom: 15, marginTop: 15 }}>Välj Skärmens Rotation</label>
                    <div id="select-screen-rotation">
                        <div className="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="Landscape" name="screenRotation" className="custom-control-input" value="Landscape" defaultChecked={this.state.screenRotation === "Landscape"} onChange={this.handleScreenRotationChange} />
                            <label className="custom-control-label" htmlFor="Landscape">Liggande</label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="Portrait" name="screenRotation" className="custom-control-input" value="Portrait" defaultChecked={this.state.screenRotation === "Portrait"} onChange={this.handleScreenRotationChange} />
                            <label className="custom-control-label" htmlFor="Portrait">Stående</label>
                        </div>
                    </div>
                    <label htmlFor="select-layout-list" className="bold" style={{ marginBottom: 15, marginTop: 15 }}>Välj layout</label>
                    <div>
                        <select id="select-layout-list" onChange={this.handleDropdownChange} className="custom-select" value={this.state.layout}>
                            {this.state.screenRotation === 'Landscape' && this.state.stopsNr === 1 && <option value={"departureBoard_OneTable"} disabled={hasMixedSelection.some(fromPointIsMixed => fromPointIsMixed)}>Avgångstavla</option>}
                            {this.state.screenRotation === 'Landscape' && <option value={`departureBoard${(this.state.stopsNr === 1 ? "" : `_Multistop_${this.state.stopsNr}x`)}`}>Delad Avgångstavla</option>}
                            {this.state.screenRotation === 'Portrait' && <option value={`departureBoard${(this.state.stopsNr === 1 ? "" : `_Multistop_${this.state.stopsNr}x`)}`}>Avgångstavla</option>}
                            {this.state.stopsNr === 1 && <option value={"countdown"} disabled={hasMixedSelection.some(fromPointIsMixed => fromPointIsMixed)}>Nedräknare</option>}
                            {this.state.stopsNr === 1 && < option value={"mixed_CountdownAndDepartureBoard"} disabled={hasMixedSelection.some(fromPointIsMixed => fromPointIsMixed)}>Nedräknare & Avgångstavla</option>}
                        </select>
                        {hasMixedSelection.some(fromPointIsMixed => fromPointIsMixed) && <div style={{ fontSize: '.75em' }}>Vissa layouter är inte valbara pga att de inte stödjer tågtrafik kombinerat med andra trafikslag.</div>}
                    </div>
                    <label htmlFor="select-table-rows" className="bold" style={{ marginBottom: 15, marginTop: 15 }}>Välj antal rader i tabell</label>
                    <div>
                        <select id="select-table-rows" onChange={this.handleTableRowsChange} className="custom-select">
                            {this.state.screenRotation === 'Landscape' && this.state.layout === 'departureBoard' && <>
                                <option value={6}>6</option>
                                <option value={8}>8</option>
                            </>}
                            {this.state.layout === 'departureBoard_OneTable' && <>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                                <option value={6}>6</option>
                                <option value={8}>8</option>
                            </>}
                            {/* These alternatives are applied both for full height portrait and two traffic types (split) portrait */
                                this.state.screenRotation === 'Portrait' && this.state.layout === 'departureBoard' && <>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                                <option value={11}>11</option>
                                <option value={13}>13</option>
                            </>}
                            {this.state.screenRotation === 'Landscape' && this.state.layout === 'countdown' && <>
                                <option value={1}>(1) + (1)</option>
                            </>}
                            {this.state.screenRotation === 'Portrait' && this.state.layout === 'countdown' && <>
                                <option value={2}>2</option>
                            </>}
                            {this.state.screenRotation === 'Landscape' && this.state.layout === 'mixed_CountdownAndDepartureBoard' && <>
                                <option value={6}>(1) + (6)</option>
                            </>}
                            {this.state.screenRotation === 'Portrait' && this.state.layout === 'mixed_CountdownAndDepartureBoard' && <>
                                <option value={6}>(1 + 6)</option>
                            </>}
                            {this.state.screenRotation === 'Landscape' && this.state.layout === 'departureBoard_Multistop_2x' && <>
                                <option value={6}>(6) + (6)</option>
                                <option value={8}>(8) + (8)</option>
                            </>}
                            {this.state.screenRotation === 'Portrait' && this.state.layout === 'departureBoard_Multistop_2x' && <>
                                <option value={4}>(4+4)</option>
                                <option value={5}>(5+5)</option>
                            </>}
                            {this.state.screenRotation === 'Landscape' && this.state.layout === 'departureBoard_Multistop_3x' && <>
                                <option value={3}>(9) + (3+3)</option>
                                <option value={4}>(11) + (4+4)</option>
                            </>}
                            {this.state.screenRotation === 'Portrait' && this.state.layout === 'departureBoard_Multistop_3x' && <>
                                <option value={3}>(3+3+3)</option>
                                <option value={4}>(4+4+4)</option>
                            </>}
                            {this.state.screenRotation === 'Landscape' && this.state.layout === 'departureBoard_Multistop_4x' && <>
                                <option value={3}>(3+3) + (3+3)</option>
                                <option value={4}>(4+4) + (4+4)</option>
                            </>}
                            {this.state.screenRotation === 'Portrait' && this.state.layout === 'departureBoard_Multistop_4x' && <>
                                <option value={3}>(3+3+3+3)</option>
                                <option value={4}>(4+4+4+4)</option>
                            </>}
                        </select>
                    </div>

                    {/* Commercial bus is currently not supported
                     <div className="form-check">
                        <input className="configurator-checkbox form-check-input" type="checkbox" id="Comercialbus" checked={this.state.trafficTypes.indexOf(16) > -1} onChange={(event) => { this.onTrafficTypeChanged(16, event) }}/>
                        <label className="configurator-checkbox-label form-check-label" htmlFor="Comercialbus">Kommersiell buss</label>
                    </div> */}

                    <br />
                    <label className="bold">Säkerhetsnyckel</label>
                    <div className={`input-group ${this.state.invalidApiKey ? "invalid-input" : ""}`}>
                        <input style={{ height: 50 }} type="text" className="form-control" value={this.state.apiKey} onChange={(event) => this.setState({ apiKey: event.target.value })} />
                    </div>
                    <div className={`error-message ${this.state.invalidApiKey ? "d-block" : "d-none"}`}>
                        Ogiltig säkerhetsnyckel. Vänligen kontakta Skånetrafikens företagsservice på 0771-77 75 75
                    </div>

                    <br />
                    <br />
                    <div style={{ width: '100%', alignItems: 'center', textAlign: 'center', paddingBottom: 24 }}>
                        <button disabled={(!this.dataIsInputted()) || this.state.apiKey === '' || this.state.createBtnLoading} className={"btn btn-warning bold"} onClick={() => {
                            this.validateApiKey(this.state.apiKey);
                        }} style={{
                            padding: '.45rem 1.8rem'
                        }}>{this.state.createBtnText}</button>
                    </div>
                </div>
            </div>
        );
    }

    handleScreenRotationChange = (event) => {
        // One_Table in portrait is same as departureBoard
        var layout = this.state.layout;
        if (event.target.value === 'Portrait' && this.state.layout === 'departureBoard_OneTable')
            layout = 'departureBoard';
        var tableRows = this.getLayoutDefaultTableRows(layout, event.target.value, this.state.stopsNr);

        this.setState({ screenRotation: event.target.value, layout: layout, tableRows: tableRows });
    };

    getLayoutId(layout) {
        return `${layout}_${this.state.screenRotation}_${this.state.tableRows}`;
    };

    dataIsInputted() {
        var isAllDataInputted = true;
        this.state.fromPointData.forEach((p) => {
            if (!(p.fromPoint && p.selectedStopPoints.length !== 0 && p.trafficTypes.length !== 0)) {
                isAllDataInputted = false;
            }
        })
        return isAllDataInputted;
    }
    
    getLayoutDefaultTableRows = (layout, rotation, stopsNr) => {
        if (layout === 'departureBoard' && rotation === 'Landscape')
            return 6;
        else if (layout === 'departureBoard_OneTable')
            return 4;
        else if (layout === 'departureBoard' && rotation === 'Portrait')
            return 4;
        else if (layout === 'countdown' && rotation === 'Landscape')
            return 1;
        else if (layout === 'countdown' && rotation === 'Portrait')
            return 2;
        else if (layout === 'mixed_CountdownAndDepartureBoard' && rotation === 'Landscape')
            return 6;
        else if (layout === 'mixed_CountdownAndDepartureBoard' && rotation === 'Portrait')
            return 6;
        else if (layout === `departureBoard_Multistop_2x` && rotation === 'Landscape')
            return 6;
        else if (layout === `departureBoard_Multistop_2x` && rotation === 'Portrait')
            return 4;
        else if (layout === `departureBoard_Multistop_${stopsNr}x` && rotation === 'Landscape')
            return 3;
        else if (layout === `departureBoard_Multistop_${stopsNr}x` && rotation === 'Portrait')
            return 3;
    };

    handleDropdownChange = (event) => {
        var tableRows = this.getLayoutDefaultTableRows(event.target.value, this.state.screenRotation, this.state.stopsNr);
        this.setState({
            layout: event.target.value,
            tableRows: tableRows
        });
    };

    handleTableRowsChange = (event) => {
        this.setState({ tableRows: Number.parseInt(event.target.value) });
    };

    renderDownloadPage() {
        return (
            <DownloadBatPage query={this.state.query} name={this.state.fromPointData[0].fromPoint.name} />
        );
    }

    getWidth() {
        return Math.max(
            document.body.scrollWidth,
            document.documentElement.scrollWidth,
            document.body.offsetWidth,
            document.documentElement.offsetWidth,
            document.documentElement.clientWidth
        );
    }

    render() {
        var configHeader = "'Bitter', serif"
        return (
            <div className="App" style={{ position: 'absolute', zIndex: 0, backgroundColor: 'rgb(240,240,232)', height: '100%', display: 'table', width: '100%' }}>
                {this.state.width > 450 && <div style={{ position: 'absolute', top: 100, left: 0, right: 0, zIndex: 0 }}><img style={{ width: '100%' }} src={background} alt="" /></div>}
                <div className="skanetrafiken-logo">
                    <img style={{ height: 32, paddingRight: 12 }} src={logo} alt="skånetrafiken" />
                    <span style={{ lineHeight: 1, fontWeight: 400, position: 'absolute', bottom: 0, paddingLeft: 15, color: '#807569', fontSize: 20, fontFamily: configHeader }}>Molnskylt</span>
                </div>
                <div style={{ position: 'absolute', bottom: 10, right: 10 }}><img style={{ height: 65, padding: 20 }} src={regionSkaneLogo} alt="" /></div>
                <div className="container px-0 px-md-3 configuration-container">
                    {!this.state.showDownload ? this.renderConfigurationForm() : this.renderDownloadPage()}
                </div>
            </div>
        );
    }
}

export default ConfigApp;
